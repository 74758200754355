<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-12"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Recherche avancée
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="font-c4 mt-6 bg-white p-6 pb-12">
            <div class="flex">
              <div class="w-1/2">
                <div class="text-left text-c13 mt-6 mb-1">
                  Nom du client
                </div>
                <inputo
                  height="45px"
                  @info="retourNom"
                />
              </div>

              <div class="w-1/2 ml-4">
                <div class="text-left text-c13 mt-6 mb-1">
                  Prénom du client
                </div>
                <inputo
                  height="45px"
                  @info="retourPrenom"
                />
              </div>
            </div>


            <div class="text-left text-c13 mt-6 mb-1">
              Référence de la commande
            </div>
            <inputo
              height="45px"
              @info="retourReference"
            />

            <div class="mt-6">
              <div class="text-left text-c13 mt-6">
                Date de la commande
              </div>
              <div class="w-full mt-2">
                <datepicker
                  height="45px"
                  placeholder="Date de la commande"
                  :chose-time="false"
                  format="dd-MM-yyyy"
                  @info="retourStart"
                />
              </div>
            </div>

            <div class="mt-6">
              <div class="text-left text-c13 mt-6">
                Date de livraison
              </div>
              <div class="w-full mt-2">
                <datepicker
                  height="45px"
                  placeholder="Date de livraison"
                  :chose-time="false"
                  format="dd-MM-yyyy"
                  @info="retourEnd"
                />
              </div>
            </div>


            <div class="mt-6">
              <div class="text-left text-c13 mb-1">
                Numéro de péniche
              </div>
              <inputo
                height="45px"
                @info="retourPeniche"
              />
            </div>

            <div class="mt-6">
              <div class="text-left text-c13 mb-1">
                Agence
              </div>

              <div class="mt-4 z-1">
                <multiselects
                  v-if="agency.length > 0"
                  :option="agency"
                  height="45px"
                  @info="retourAgence"
                  display-key="label"
                  width="100%"
                  background="#fff"
                />
              </div>
            </div>

            <div class="flex justify-center mt-10">
              <buton
                class="w-2/3"
                label="Rechercher"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import fermer from '../../assets/icons/fermer.svg'
import search from '../../assets/icons/search.svg'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import inputo from '../helper/form/input'
import datepicker from '../helper/form/datePicker'
import buton from '../helper/add/button'
import multiselects from "@/component/helper/form/multiselect";

export default {
  name: 'Success',
  components: {
    PopupBase,
    inputo,
    datepicker,
    buton,
    multiselects
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        fermer,
        search
      },
      error: null,
      charge: false,
      response: {},
      agency: []
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.getAllAgence()
  },

  methods: {
    continuer () {
      this.$emit('oga', this.response)
    },

    getAllAgence () {
      http.get(apiroutes.baseURL + apiroutes.allAgency)
          .then(response => {
            console.log('agence', response)
            this.agency = response
          })
          .catch(error => {
            console.log(error)
          })
    },

    retourAgence(a) {
      this.response.agencyId = a.id
      console.log(this.response)
    },

    retourStart (answer) {
      if (answer !== '') {
        this.response.createdAt = answer
      }
    },

    retourEnd (answer) {
      if (answer !== '') {
        this.response.deliveryDate = answer
      }
    },

    retourReference (a) {
      if (a !== '') {
        this.response.reference = a
      }
    },

    retourNom (a) {
      if (a !== '') {
        this.response.name = a
      }
    },

    retourPrenom (a) {
      if (a !== '') {
        this.response.surname = a
      }
    },

    retourPeniche (answer) {
      if (answer !== '') {
        this.response.houseboat = answer
      }
    },

    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }

    .cardinal {
      width: 50%;
      border-radius: 12px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      max-height: 500px;
      overflow-y: scroll;
      overflow-x: hidden;
      margin: auto;
      border-radius: 12px;
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
