<template>
  <div >
    <div class="text-left" v-if="selectedItem === null">
      <div class="flex items-center">
        <div class="w-2/3 flex text-left text-c48 items-center font-c6">
          <icon
              :data="icons.back"
              height="70"
              width="70"
              class="mr-2 cursor-pointer"
              original
              @click="$emit('oga', false)"
          />
          <div class="ml-4">
            Bon à lancer
          </div>
        </div>
      </div>


      <div class="bg-white p-6 mt-10 rounded-5 bloc text-left">
        <div class="flex bloc text-90 text-c5 text-c16">
          <div class="w-1/4">
            FOURNISSEURS
          </div>
          <div class="w-1/4 ml-4">
            QTÉ DE COMMANDES
          </div>
          <div class="w-1/4 ml-4">
            NOMBRE DE VERRES
          </div>
          <div class="w-1/4 ml-4">
            NUMERO
          </div>
          <div class="w-20 ml-4"/>
        </div>

        <div class="diviser mt-6" />

        <div v-for="(i, index) in stock" :key="index" @click="selected(i)">
          <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer mt-6" >
            <div class="w-1/4">
              {{ i.name }}
            </div>

            <div class="w-1/4 ml-4 flex items-center">
                <div class="text-c16">
                 {{ i.qte }}
                </div>
            </div>

            <div class="w-1/4 flex items-center ml-4">
              <div class="text-c16">
                {{ i.nombre }}
              </div>
            </div>

            <div class="w-1/4 ml-4">
              {{ i.numero }}
            </div>

            <div class="w-20 ml-4">
              <icon
                  :data="icons.upload"
                  height="15"
                  width="15"
                  class="cursor-pointer"
                  original
              />
            </div>
          </div>
          <div class="diviser mt-6" />
        </div>
      </div>
    </div>

    <div v-if="selectedItem !== null">
      <fournisor :donnes="selectedItem" v-if="selectedItem !== null" @oga="selectedItem = null"/>
    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import upload from '../../../assets/icons/download.svg'
import fournisor from './detailFournisseur'

export default {
  name: "Index",

  components: {
    fournisor
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back,
        upload
      },
      stock: [
        {
          name: 'Stock OHO',
          qte: 13,
          nombre: 26,
          numero: 'OHO-589'
        },
        {
          name: 'V-LAB',
          qte: 33,
          nombre: 66,
          numero: 'VLAB-3878'
        },
        {
          name: 'Connants',
          qte: 10,
          nombre: 20,
          numero: 'C-39879'
        }
      ],
      selectedItem: null
    }
  },

  created () {
  },

  methods: {
    selected (item) {
      this.selectedItem = item
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser {
  height: 1px;
  background-color: #DCDCE4;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
</style>
