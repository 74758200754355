<template>
  <div class="p-10 pr-16 all">
    <filtre
      :activation="activFiltre"
      v-if="activFiltre"
      @oga="backFiltre"
    />

    <div v-if="activeBon === false">
      <div
        class="flex items-center"
      >
        <div class="w-2/3 flex text-left text-c48 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
          />
          <div class="ml-4">
            Commandes <span v-if="user !== null">({{ user }})</span>
          </div>
        </div>

        <div class="w-1/4">
          <div class="flex justify-end">
            <div class="w-4/5 mr-4">
              <multiselect
                :icon="icons.filter"
                height="54px"
                background="white"
                :icon-width="25"
                width="100%"
                :value-t="valueFiltre"
                :option="filtre"
                @info="retourFiltre"
              />
            </div>
          </div>
        </div>

        <boutton
          class="w-1/4"
          label="Filtrage poussé"
          radius="5px"
          @info="activFiltre = true"
        />
      </div>

      <div class="mt-6">
        <global-view
          v-if="!reloadSession"
          @info="retourEtape"
          @user="retourUser"
          :filtre="valueFiltre"
          :allFilter="filtrage"
          :temoinFilter="temoin"
        />
      </div>
    </div>

    <div v-if="activeBon">
      <bon @oga="activeBon = false" />
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import globalView from '../../component/cdevs/commandes/globalData'
import bon from '../../component/cdevs/commandes/aLancer'
import filter from '../../assets/icons/filter.svg'
import multiselect from '../../component/helper/form/multiselect'
import boutton from "@/component/helper/add/button"
import filtre from "@/component/popup/filtre";

export default {
  name: "Index",

  components: {
    globalView,
    bon,
    multiselect,
    boutton,
    filtre
  },

  data () {
    return {
      icons: {
        back,
        filter
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
      activeBon: false,
      filtre: ['À valider', 'Validées', 'Rejetées', 'Archivées'],
      valueFiltre: 'À valider',
      activFiltre: false,
      filtrage: null,
      temoin: null
    }
  },

  created () {
    this.$store.dispatch('saveQuote', null)
    this.$store.dispatch('saveBasket', [])
  },

  methods: {
    retourClick () {
      this.$router.push('/nouveauDevis')
    },

    backFiltre (answer) {
      if (answer !== false) {
        this.filtrage = answer
        this.temoin = new Date().getTime()
        this.valueFiltre = 'À valider'
      }
      this.activFiltre = false
    },

    retourFiltre (answer) {
      this.valueFiltre = answer
    },

    retourUser (answer) {
      this.user = answer
    },

    retourEtape (answer) {
      this.selectedItem = answer
      this.activeDetail = true
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 700px;
}
</style>
