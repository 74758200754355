<template>
  <div class="text-left">
    <div class="flex items-center">
      <div class="w-2/3 flex text-left text-c48 items-center font-c6">
        <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="$emit('oga', false)"
        />
        <div class="ml-4">
          Fournisseur {{ donnes.name }}
        </div>
      </div>
    </div>


    <div class="bg-white p-6 mt-10 pb-20 rounded-5 bloc text-left">
      <div class="flex mt-4">
        <div class="w-4/5 text-c24 font-c5">{{ donnes.numero }} | NOMBRE DE VERRES : {{ donnes.nombre }}</div>
        <div class="w-1/5">
          <bouton label="Exporter la fiche" border="1px solid #DDDDDD" background="rgba(242, 245, 249, 0.5)" color="#3521B5" size="16px" :icon="icons.esport"/>
        </div>
      </div>

      <div class="w-full flex text-90 text-c16 font-c6 text-left mt-10">
        <div class="w-1/4">
          NOM COMMERCIAL
        </div>
        <div class="w-1/5 ml-4">
          NOM FNSEUR
        </div>
        <div class="w-1/6 ml-6">
          S/COUCHE
        </div>
        <div class="w-68 ml-4">
          IND.
        </div>
        <div class="w-68 ml-4">
          CYL.
        </div>
        <div class="w-68 ml-4">
          AXE
        </div>
        <div class="w-79 ml-4">
          ADD.
        </div>
        <div class="w-1/6 ml-4">
          PATIENT
        </div>
        <div class="w-20 ml-4" />
        <div />
      </div>

      <div class="diviser mt-6"/>

      <div class="flex justify-center mt-12" v-if="charge && allVerre.length === 0">
        <easy-spinner
            type="dots"
            size="30"
        />
      </div>

      <div v-for="(item, index) in allVerre" :key="index">
        <div
            class="w-full flex items-center text-c16 font-c4 text-left mt-6 item cursor-pointer"
            @click="selected(item)"
        >
          <div class="w-1/4">
            {{item.name.substr(0, 17)}} <span v-if="item.name.length > 17">..</span>
          </div>

          <div class="w-1/5 ml-4">
            {{item.meta.supplier.brand}}
          </div>

          <div class="w-1/6 ml-6">
          <span v-if="item.glassDetails !== null">
            {{item.glassDetails.underCoat}}
          </span>
            <span v-if="!item.glassDetails">
            -
          </span>
          </div>

          <div class="w-68 ml-4">
            {{item.indice}}
          </div>

          <div class="w-68 ml-4">
           <span v-if="item.glassDetails !== null">
            {{item.glassDetails.cylinder}}
          </span>
            <span v-if="!item.glassDetails">
            -
          </span>
          </div>

          <div class="w-68 ml-4">
          <span v-if="item.glassDetails !== null">
            {{item.glassDetails.axis}}
          </span>
            <span v-if="!item.glassDetails">
            -
          </span>
          </div>

          <div class="w-79 ml-4">
         <span v-if="item.glassDetails !== null">
            {{item.glassDetails.additionMax}}
          </span>
            <span v-if="!item.glassDetails">
            -
          </span>
          </div>

          <div class="w-1/6 ml-4 text-yel">
            Alida Tauzier
          </div>
          <div class="w-20 ml-4">
            <icon
                :data="icons.delet"
                height="25"
                width="25"
                class="icone"
                original
            />
          </div>
        </div>

        <div class="diviser mt-6"/>
      </div>

    </div>
  </div>
</template>

<script>
import back from '../../../assets/icons/backBut.svg'
import delet from '../../../assets/icons/delete.svg'
import esport from '../../../assets/icons/export.svg'
import verre from './verre.json'
import bouton from '../../helper/add/button'

export default {
  name: "Index",

  components: {
    bouton
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        back,
        delet,
        esport
      },
      devis: ['', '', ''],
      allVerre: verre,
      charge: false
    }
  },

  created () {
  },

  methods: {
    retourAdd () {
      this.$router.push('/panier')
    },

  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.diviser {
  height: 1px;
  background-color: #DCDCE4;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
</style>
